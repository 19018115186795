import React from 'react';

const PersonMember = ({ imgPath, name, content }) => {
  return (
    <div
      className="member col-xl col-lg-4 col-md-4 col-sm-6 col-9"
      data-aos="fade-up"
    >
      <div className="pic">
        <img src={imgPath} className="img-fluid" alt="Person icon" size="" />
      </div>
      <div className="member-info">
        <h3>{name}</h3>
        <span>{content}</span>
      </div>
    </div>
  );
};

export default PersonMember;
