import React, { useEffect, useState } from 'react';
import './App.css';

import AOS from 'aos';
import 'aos/dist/aos.css';
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';

import IntelligiaLogoMaleR from './img/intelligiaLogo/Logo_INTELLIGIA_male_R.webp';
import IntelligiaLogoDuzeR from './img/intelligiaLogo/Logo_INTELLIGIA_duze_R.webp';
import ModalComponent from './Components/ModalComponent/ModalComponent';
import ServiceCard from './Components/ServiceCard/ServiceCard';
import { useTranslation } from 'react-i18next';
import PureCounter from '@srexi/purecounterjs';
import ChooseLang from './Components/ChooseLang/ChooseLang';
import CooperationCard from './Components/CooperationCard/CooperationCard';

import viessmanRef from './img/references/viessman.webp';
import viessmanLogo from './img/businessesLogo/Viessmann.webp';
import upLogo from './img/businessesLogo/uniwersytet_przyrodniczy_poznan.webp';
import energoPoserRef from './img/references/referencja_Energoprosper_page-0001.webp';
import energoPoserLogo from './img/businessesLogo/logo_energoprosper.webp';
import teamMate from './img/team/team-1.webp';
import PersonMember from './Components/PersonMember/PersonMember';
import alhambraTestimonialGb from './img/references/alhambra-gb.webp';
import alhambraTestimonialPl from './img/references/alhambra-pl.webp';
import alhambraLogo from './img/businessesLogo/alhambra.webp';
import go4Energy from './img/cooperation/go4energy.webp';

import Photovoltaic from './img/portfolio/PV.webp';
import cableBox from './img/portfolio/cable_box.webp';
import turbine from './img/portfolio/turbine.webp';
import wave from './img/portfolio/wave.webp';
import leedAp from './img/portfolio/LEED_AP.webp';
import leedLevels from './img/portfolio/Levels-of-LEED_logo.webp';
import wellLogo from './img/portfolio/WELL_logo.webp';
import wellHsrLogo from './img/portfolio/WELL_HSR_logo.webp';
import ServiceCardImage from './Components/ServiceCard/ServiceCardImage';

function App() {
  const [mobileNavBar, setMobileNavBar] = useState(false);
  (() => {
    /**
     * Easy selector helper function
     */
    const select = (el, all = false) => {
      if (el) {
        el = el.trim();
        if (all) {
          return [...document.querySelectorAll(el)];
        } else {
          return document.querySelector(el);
        }
      }
    };

    /**
     * Easy event listener function
     */
    const on = (type, el, listener, all = false) => {
      let selectEl = select(el, all);
      if (selectEl) {
        if (all) {
          selectEl.forEach((e) => e.addEventListener(type, listener));
        } else {
          selectEl.addEventListener(type, listener);
        }
      }
    };

    /**
     * Easy on scroll event listener
     */
    const onscroll = (el, listener) => {
      el.addEventListener('scroll', listener);
    };

    /*
     * Scrolls to an element with header offset
     */
    const scrollto = (el) => {
      let header = select('#header');
      let offset = header.offsetHeight;

      if (!header.classList.contains('header-scrolled')) {
        offset -= 24;
      }

      let elementPos = select(el).offsetTop;
      window.scrollTo({
        top: elementPos - offset,
        behavior: 'smooth',
      });
    };

    /**
     * Toggle .header-scrolled class to #header when page is scrolled
     */

    let selectHeader = select('#header');
    if (selectHeader) {
      const headerScrolled = () => {
        if (window.scrollY > 100) {
          selectHeader.classList.add('header-scrolled');
          //selectHeader.classList.add("white-text");
        } else {
          selectHeader.classList.remove('header-scrolled');
          // selectHeader.classList.remove("white-text");
        }
      };
      window.addEventListener('load', headerScrolled);
      onscroll(document, headerScrolled);
    }

    /**
     * Back to top button
     */
    let backtotop = select('.back-to-top');
    if (backtotop) {
      const toggleBacktotop = () => {
        if (window.scrollY > 100) {
          backtotop.classList.add('active');
        } else {
          backtotop.classList.remove('active');
        }
      };
      window.addEventListener('load', toggleBacktotop);
      onscroll(document, toggleBacktotop);
    }

    /**
     * Mobile nav dropdowns activate
     */
    on(
      'click',
      '.navbar .dropdown > a',
      function (e) {
        if (select('#navbar').classList.contains('navbar-mobile')) {
          e.preventDefault();
          this.nextElementSibling.classList.toggle('dropdown-active');
        }
      },
      true
    );

    /**
     * Scrool with ofset on links with a class name .scrollto
     */
    on(
      'click',
      '.scrollto',
      function (e) {
        if (select(this.hash)) {
          e.preventDefault();

          let navbar = select('#navbar');
          if (navbar.classList.contains('navbar-mobile')) {
            navbar.classList.remove('navbar-mobile');
            let navbarToggle = select('.mobile-nav-toggle');
            navbarToggle.classList.toggle('bi-list');
            navbarToggle.classList.toggle('bi-x');
          }
          scrollto(this.hash);
        }
      },
      true
    );

    /**
     * Scroll with ofset on page load with hash links in the url
     */
    window.addEventListener('load', () => {
      // document.querySelector("#navbar .scrollto").classList.add("active");
      if (window.location.hash) {
        if (select(window.location.hash)) {
          scrollto(window.location.hash);
        }
      }
    });
  })();

  /* change transparent of header when scroll */
  const headerToScroll = (e) => {
    if (window.pageYOffset > 20) {
      document.querySelector('#header').classList.add('header-scrolled');
    } else {
      document.querySelector('#header').classList.remove('header-scrolled');
    }
  };

  SwiperCore.use([Autoplay]);

  useEffect(() => {
    /* Initialize counter */
    new PureCounter();
    /* Initialize AOS library */
    AOS.init({
      duration: 1000,
      easing: 'ease-in-out',
      once: true,
      mirror: false,
    });

    window.addEventListener('scroll', headerToScroll);
    window.addEventListener('click', headerToScroll);
  }, []);

  const mobileHandler = () => {
    if (window.innerWidth < 992) setMobileNavBar(!mobileNavBar);
  };

  const { t, i18n } = useTranslation('common');

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div>
      <header id="header" className="fixed-top header-transparent">
        <div className="container d-flex align-items-center justify-content-between">
          <div className="logo">
            <h6 className="text-light">
              <a href="/">
                <img
                  size=""
                  src={IntelligiaLogoDuzeR}
                  alt="Gold Intelligia logo"
                  className="img-fluid w-50 h-50"
                />
              </a>
            </h6>
          </div>
          <nav
            id="navbar"
            className={`navbar ${mobileNavBar && 'navbar-mobile'}`}
            onClick={mobileHandler}
          >
            <ul>
              <li>
                <a
                  className={`nav-link scrollto ${
                    !mobileNavBar && 'dark-text' && mobileNavBar && 'white-text'
                  }`}
                  href="#hero"
                >
                  {t('navBar.home')}
                </a>
              </li>
              <li>
                <a
                  className={`nav-link scrollto ${
                    !mobileNavBar && 'dark-text' && mobileNavBar && 'white-text'
                  }`}
                  href="#about"
                >
                  {t('navBar.aboutUs')}
                </a>
              </li>
              <li>
                <a
                  className={`nav-link scrollto ${
                    !mobileNavBar && 'dark-text' && mobileNavBar && 'white-text'
                  }`}
                  href="#services"
                >
                  {t('navBar.services')}
                </a>
              </li>
              <li>
                <a
                  className={`nav-link scrollto ${
                    !mobileNavBar && 'dark-text' && mobileNavBar && 'white-text'
                  }`}
                  href="#team"
                >
                  {t('navBar.team')}
                </a>
              </li>
              <li>
                <a
                  className={`nav-link scrollto ${
                    !mobileNavBar && 'dark-text' && mobileNavBar && 'white-text'
                  }`}
                  href="#cooperation"
                >
                  {t('navBar.cooperation')}
                </a>
              </li>
              <li>
                <a
                  className={`nav-link scrollto ${
                    !mobileNavBar && 'dark-text' && mobileNavBar && 'white-text'
                  }`}
                  href="#contact"
                >
                  {t('navBar.contact')}
                </a>
              </li>

              <li>
                <ChooseLang
                  mobileNavBar={mobileNavBar}
                  changeLanguage={changeLanguage}
                />
              </li>
            </ul>
            <i
              className={`bi  ${
                !mobileNavBar ? 'bi-list' : 'bi-x'
              } mobile-nav-toggle`}
            />
          </nav>
        </div>
      </header>
      <section id="hero">
        <div className="hero-container" data-aos="fade-up">
          <div className="logoSection d-flex flex-column justify-content-center align-items-center">
            <div className="w-60">
              <img
                src={IntelligiaLogoMaleR}
                size=""
                alt="Gold Intelligia logo"
                className="img-fluid "
              />
            </div>
            <h1 className="mt-4">{t('welcome.title')}</h1>
          </div>
          <a
            href="#about"
            className="btn-get-started scrollto"
            aria-label="Scroll to next section"
          >
            <i className="bi bi-arrow-down-square-fill" />
          </a>
        </div>
      </section>
      <main id="main">
        <section id="about" className="about">
          <div className="container" onScroll={headerToScroll}>
            <div className="row no-gutters">
              <div
                className="content col-xl-5 d-flex align-items-stretch"
                data-aos="fade-up"
              >
                <div className="content">
                  <h2>{t('aboutUs.aboutUs')}</h2>
                  <p className="lightweight-text">{t('aboutUs.main')}</p>
                </div>
              </div>
              <div className="col-xl-7 d-flex align-items-stretch">
                <div className="icon-boxes d-flex flex-column justify-content-center">
                  <div className="row services flex">
                    <div
                      className="col-md-6 icon-box"
                      data-aos="fade-up"
                      data-aos-delay={100}
                    >
                      <i className="bi bi-currency-exchange" />
                      <h3>{t('aboutUs.energySave')}</h3>
                      <p>{t('aboutUs.energySaveText')}</p>
                    </div>
                    <div
                      className="col-md-6 icon-box"
                      data-aos="fade-up"
                      data-aos-delay={100}
                    >
                      <i className="bi bi-puzzle" />
                      <h3>{t('aboutUs.openMinds')}</h3>
                      <p>{t('aboutUs.openMindsText')}</p>
                    </div>
                    <div
                      className="col-md-6 icon-box"
                      data-aos="fade-up"
                      data-aos-delay={100}
                    >
                      <i className="bi bi-building" />
                      <h3>{t('aboutUs.cooperation')}</h3>
                      <p>{t('aboutUs.cooperationText')}</p>
                    </div>
                    <div
                      className="col-md-6 icon-box"
                      data-aos="fade-up"
                      data-aos-delay={100}
                    >
                      <i className="bi bi-shield-fill-check" />
                      <h3>{t('aboutUs.security')}</h3>
                      <p>{t('aboutUs.securityText')}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="services" className="services">
          <div className="container">
            <div
              className="section-title"
              data-aos="fade-in"
              data-aos-delay={100}
            >
              <h2>{t('services.main')}</h2>
              <p>{t('services.text')}</p>
            </div>
            <ResponsiveMasonry columnsCountBreakPoints={{ 0: 1, 766: 2 }}>
              <Masonry gutter="2rem">
                <ServiceCard
                  id="Photovoltaic"
                  header={t('services.Photovoltaic')}
                  content={t('services.PhotovoltaicContent')}
                  img={{ src: Photovoltaic, alt: 'Multiple solar panels' }}
                  iconName="bi-lightning-charge"
                />
                <ServiceCard
                  id="BuildingAutomation"
                  header={t('services.BuildingAutomation')}
                  content={t('services.BuildingAutomationContent')}
                  img={{ src: cableBox, alt: 'Electrical distribution panel' }}
                  iconName="bi-building-gear"
                />
                <ServiceCard
                  id="ArtificalInteligence"
                  header={t('services.ArtificalInteligence')}
                  content={t('services.ArtificalInteligenceContent')}
                  img={{
                    src: wave,
                    alt: 'Particles waves on dark blue background',
                  }}
                  iconName="bi-cpu"
                />
                <ServiceCard
                  id="EnergyEfficiency"
                  header={t('services.EnergyEfficiency')}
                  content={t('services.EnergyEfficiencyContent')}
                  img={{
                    src: turbine,
                    alt: 'Wind turbine and two solar panels',
                  }}
                  iconName="bi-graph-up"
                >
                  {t('services.EnergyEfficiencyContent2')}
                </ServiceCard>
                <ServiceCard
                  id="LeedCertification"
                  header={t('services.LeedCertification')}
                  content={t('services.LeedCertificationContent')}
                  img={{
                    src: leedLevels,
                    alt: 'Certified, Silver, Gold and Platinum LEED Certification Logos',
                  }}
                  iconName="bi-patch-check"
                >
                  <div>
                    <p>
                      <b>{t('services.LeedCertificationContentListHeader')}</b>
                    </p>
                    <ul>
                      {t('services.LeedCertificationContentList', {
                        returnObjects: true,
                      }).map((item, i) => (
                        <li key={i}>{`${item},`}</li>
                      ))}
                    </ul>
                    <ServiceCardImage
                      src={leedAp}
                      alt="Both BD+C and ID+C LEED AP logos"
                    />
                    <p className="mt-3">
                      {t('services.LeedCertificationContentDetail')}
                    </p>
                    <ul>
                      {t('services.LeedCertificationContentDetailList', {
                        returnObjects: true,
                      }).map((item, i) => (
                        <li key={i}>{`${item},`}</li>
                      ))}
                    </ul>
                  </div>
                </ServiceCard>
                <ServiceCard
                  id="WellCertification"
                  header={t('services.WellCertification')}
                  content={t('services.WellCertificationContent')}
                  img={{ src: wellLogo, alt: 'WELL Logo' }}
                  iconName="bi-patch-check"
                >
                  <div>
                    <p>{t('services.WellCertificationContent2')}</p>
                    <ServiceCardImage src={wellHsrLogo} alt="WELL HSR Logo" />
                  </div>
                </ServiceCard>
              </Masonry>
            </ResponsiveMasonry>
          </div>
        </section>
        <section id="counts" className="counts  section-bg">
          <div className="container">
            <div className="row no-gutters">
              <div className="col-lg-4 col-md-4 d-md-flex align-items-md-stretch">
                <div className="count-box">
                  <i className="bi bi-emoji-laughing-fill" />
                  <span
                    data-purecounter-start={0}
                    data-purecounter-end={76}
                    data-purecounter-duration={1}
                    className="purecounter"
                  />
                  <p>
                    <strong>{t('counter.clients')}</strong>
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 d-md-flex align-items-md-stretch">
                <div className="count-box">
                  <i className="bi bi-code-slash" />
                  <span
                    data-purecounter-start={0}
                    data-purecounter-end={32}
                    data-purecounter-duration={1}
                    className="purecounter"
                  />
                  <p>
                    <strong>{t('counter.projects')}</strong>
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-3 d-md-flex align-items-md-stretch">
                <div className="count-box">
                  <i className="bi bi-wrench" />
                  <span
                    data-purecounter-start={0}
                    data-purecounter-end={38}
                    data-purecounter-duration={1}
                    className="purecounter"
                  />
                  <p>
                    <strong>{t('counter.tech')}</strong>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="testimonials" className="testimonials section-bg">
          <div className="container">
            <div
              className="section-title"
              data-aos="fade-in"
              data-aos-delay={100}
            >
              <h2 className="white-text">{t('recomendation.main')}</h2>
            </div>
            <div
              className="testimonials-slider swiper-container"
              data-aos="fade-up"
              data-aos-delay={100}
            >
              <Swiper
                spaceBetween={50}
                modules={[Navigation, Pagination]}
                slidesPerView={1}
                loop={true}
                pagination={{ clickable: true }}
                autoplay={{ delay: 3000 }}
              >
                <SwiperSlide>
                  <ModalComponent
                    logo={{ src: viessmanLogo, alt: 'Viessmann logo' }}
                    testimonial={viessmanRef}
                    paragraphContent={t('recomendation.viessmann')}
                  />
                </SwiperSlide>
                <SwiperSlide data-swiper-autoplay={6000}>
                  <ModalComponent
                    logo={{ src: alhambraLogo, alt: 'Alhambra Logo' }}
                    testimonial={{
                      gb: alhambraTestimonialGb,
                      pl: alhambraTestimonialPl,
                    }}
                    paragraphContent={t('recomendation.alhambra')}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <ModalComponent
                    logo={{
                      src: upLogo,
                      alt: 'Poznań University of Life Sciences Logo',
                    }}
                    paragraphContent={t('recomendation.up')}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <ModalComponent
                    logo={{ src: energoPoserLogo, alt: 'Energoprosper logo' }}
                    testimonial={energoPoserRef}
                    paragraphContent={t('recomendation.energoPoser')}
                  />
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </section>
        <section id="team" className="team">
          <div className="container">
            <div
              className="section-title"
              data-aos="fade-in"
              data-aos-delay={100}
            >
              <h2>{t('team.main')}</h2>
              <p>{t('team.text')}</p>
            </div>
            <div className="row justify-content-around">
              <PersonMember
                imgPath={teamMate}
                name={'Sławomir Sowa'}
                content={t('team.CEO')}
              />
              <PersonMember
                imgPath={teamMate}
                name={'Klaudia Bartnicka'}
                content={'CMO'}
              />
              <PersonMember
                imgPath={teamMate}
                name={'Jan Szymenderski'}
                content={'CTO'}
              />
              <PersonMember
                imgPath={teamMate}
                name={'Magdalena Szłapka'}
                content={'CFO'}
              />
              <PersonMember
                imgPath={teamMate}
                name={'Bartłomiej Musiał'}
                content={'CLO'}
              />
            </div>
          </div>
        </section>
        <section id="cooperation" className="cooperation section-bg">
          <div className="container-lg">
            <div
              className="section-title"
              data-aos="fade-in"
              data-aos-delay={100}
            >
              <h2>{t('cooperation.main')}</h2>
            </div>
            <div className="d-flex flex-column align-items-center">
              <CooperationCard
                name="Alhambra"
                description={t('cooperation.alhambraContent')}
                imgPath={alhambraLogo}
              />
            </div>
            <div className="d-flex flex-column align-items-center">
              <CooperationCard
                name="Go4Energy"
                description={t('cooperation.Go4energyContent')}
                imgPath={go4Energy}
              />
            </div>
          </div>
        </section>
        <section id="contact" className="contact section-bg">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h2 className="white-text">{t('contact.main')}</h2>
              <p className="white-text">{t('contact.text')}</p>
            </div>
            <div className="d-flex flex-column flex-md-row flex-wrap column-gap-3">
              <div style={{ flex: 1 }} className="info-box mb-4">
                <i className="bi bi-map-fill" />
                <h3>{t('contact.addresses')}</h3>
                <div className="d-flex flex-column justify-content-around">
                  <div className="text-center">
                    <br />
                    <h4 className="text-black" style={{ fontSize: '1rem' }}>
                      {t('contact.mainOffice')}
                    </h4>
                    <p style={{ marginTop: -10 }}>
                      ul. Grzybowska 87
                      <br />
                      00-844 Warszawa, Poland
                      <br />
                    </p>
                    <br />
                  </div>
                  <div className="text-center">
                    <h4 className="text-black" style={{ fontSize: '1rem' }}>
                      {t('contact.poznanDepartment')}
                    </h4>
                    <p style={{ marginTop: -10 }}>
                      ul. Keplera 1/5
                      <br />
                      60-158 Poznań, Poland
                      <br />
                    </p>
                  </div>
                </div>
              </div>
              <div style={{ flex: 1 }} className="info-box mb-4">
                <i className="bi bi-signpost-fill" />
                <h3>{t('contact.emailUs')}</h3>
                <a
                  href="mailto:cooperation@intelligia.eu"
                  className="contact-link"
                >
                  <p>cooperation@intelligia.eu</p>
                </a>
                <h3>{t('contact.main')}</h3>
                <a href="mailto:career@intelligia.eu" className="contact-link">
                  <p>contact@intelligia.eu</p>
                </a>
                <h3>{t('contact.career')}</h3>
                <a href="mailto:career@intelligia.eu" className="contact-link">
                  <p>career@intelligia.eu</p>
                </a>
              </div>
              <div className="break-flex" />
              <div className="info-box mb-4 flex-fill">
                <i className="bi bi-telephone-fill" />
                <h3>{t('contact.callUs')}</h3>
                <a href="tel:+48 224795788" className="contact-link">
                  <p>+48 22 479 57 88</p>
                </a>
              </div>
              <div className="break-flex" />
              <div className="info-box flex-fill map">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2443.6024943715533!2d20.97574601591158!3d52.23243936525234!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471ecc9d32700001%3A0x73e368b918fe1f73!2sGrzybowska%2087%2C%2000-844%20Warszawa!5e0!3m2!1sen!2spl!4v1644962837253!5m2!1sen!2spl"
                  loading="lazy"
                  className="mb-4 mb-lg-0"
                  title="Map"
                  style={{ border: 0, width: '100%', height: '100%' }}
                  allowFullScreen
                />
              </div>
            </div>
          </div>
        </section>
      </main>
      <footer id="footer">
        <div className="footer-top">
          <div className="container">
            <div className="col Contact">
              <div className="col-md-8 col-lg-6 m-auto">
                <div className="footer-info dark-text">
                  <h4 className="dark-text">{t('contact.links')}</h4>
                  <a href="#hero">{t('navBar.home')}</a>
                  <br />
                  <a href="#about">{t('aboutUs.aboutUs')}</a>
                  <br />
                  <a href="#services">{t('navBar.services')}</a>
                  <br />
                  <a href="#contact">{t('navBar.contact')}</a>
                  <br />
                </div>
              </div>
              <div className="col-md-8 col-lg-6 m-auto">
                <div className="footer-info dark-text">
                  <h4 className="dark-text">{t('navBar.ourServices')}</h4>
                  <a href="#BuildingAutomation">
                    {t('services.BuildingAutomation')}
                  </a>
                  <br />
                  <a href="#Photovoltaic">{t('services.Photovoltaic')}</a>
                  <br />
                  <a href="#ArtificalInteligence">
                    {t('services.ArtificalInteligence')}
                  </a>
                  <br />
                  <a href="#LeedCertification">
                    {t('services.LeedCertification')}
                  </a>
                  <br />
                  <a href="#EnergyEfficiency">
                    {t('services.EnergyEfficiency')}
                  </a>
                  <br />
                </div>
              </div>
              <div className="col-md-8 col-lg-6 m-auto">
                <div className="footer-info dark-text">
                  <h3>Intelligia Sp. z o.o.</h3>
                  <p className="pb-3">
                    <em>{t('contact.addresses')}</em>
                  </p>
                  <div className="d-flex flex-column justify-content-around">
                    <div className="text-center">
                      <h4 className="text-black">{t('contact.mainOffice')}</h4>
                      <p style={{ marginTop: -20 }}>
                        ul. Grzybowska 87
                        <br />
                        00-844 Warszawa, Poland
                      </p>
                    </div>
                    <br />
                    <div className="text-center">
                      <h4 className="text-black">
                        {t('contact.poznanDepartment')}
                      </h4>
                      <p style={{ marginTop: -20 }}>
                        ul. Keplera 1/5
                        <br />
                        60-158 Poznań, Poland
                        <br />
                      </p>
                    </div>
                    <br />
                  </div>
                  <div>
                    <p>
                      <strong>KRS:</strong> 0000917600
                      <br />
                      <strong>NIP:</strong> 1182228145
                      <br />
                      <strong>Phone:</strong>
                    </p>
                  </div>
                  <a href="tel:+48 224795788" className="contact-link">
                    <p>+48 22 479 57 88</p>
                  </a>
                  <strong>Fax:</strong> 222 034 154
                  <br />
                  <strong>{t('aboutUs.cooperation')}:</strong>
                  <a
                    href="mailto:cooperation@intelligia.eu"
                    className="contact-link"
                  >
                    <p>cooperation@intelligia.eu</p>
                  </a>
                  <strong>{t('contact.main')}</strong>
                  <a
                    href="mailto:career@intelligia.eu"
                    className="contact-link"
                  >
                    <p>contact@intelligia.eu</p>
                  </a>
                  <strong>{t('contact.career')}</strong>
                  <a
                    href="mailto:career@intelligia.eu"
                    className="contact-link"
                  >
                    <p>career@intelligia.eu</p>
                  </a>
                  <br />
                  <p />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="copyright">
            © Copyright
            <strong>
              <span> Intelligia Sp. z o.o.</span>
            </strong>{' '}
            {t('footer')}
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;
