import React from "react";
import Flag from "react-world-flags";
import storageAvailable from "../../Utils/isStorageAvailable";

const ChooseLang = ({ mobileNavBar, changeLanguage }) => {
  const mockData = ["pl", "gb", "de"];

  const changeLangHandler = (lang) => {
    changeLanguage(lang);
    if (storageAvailable("localStorage")) {
      localStorage.setItem("lang", lang);
    }
  };

  return (
    <div
      className={`nav-link scrollto d-flex flex-row ${
        mobileNavBar ? "w-25 mx-4" : "mx-2"
      }`}
    >
      {mockData.map((lang, i) => (
        <Flag
          key={i}
          code={lang}
          onClick={() => changeLangHandler(lang)}
          className="cursor-pointer mx-2"
          height="18"
          alt={`${lang} flag`}
        />
      ))}
    </div>
  );
};

export default ChooseLang;
