import { useState } from "react";
import AnimateHeight from "react-animate-height";
import ReadMoreButton from "./ReadMoreButton";
import ServiceCardImage from "./ServiceCardImage";

const ServiceCard = ({ id, header, content, children, img, iconName }) => {
  const [readMore, setReadMore] = useState(false);
  const openReadMore = () => setReadMore(true);
  const closeReadMore = () => setReadMore(false);
  return (
    <div id={id}>
      <div className="icon-box" data-aos="fade-up" data-aos-delay={100}>
        <div className="icon">
          <i className={`bi ${iconName}`} />
        </div>
        <h3 className="title">{header}</h3>
        <p className="description mb-3">{content}</p>
        <ServiceCardImage src={img.src} alt={img.alt} />
        {children && (
          <div>
            <AnimateHeight
              height={readMore ? "auto" : 0}
              className="description mt-3 mb-2"
            >
              {children}
            </AnimateHeight>
            <ReadMoreButton
              openAction={openReadMore}
              closeAction={closeReadMore}
              readMore={readMore}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ServiceCard;
