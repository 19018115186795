const CooperationCard = ({ imgPath, imgClassName, name, description }) => {
  return (
    <div
      className="p-4 mb-3 col-10 d-flex flex-column flex-md-row flex-lg-row flex-xl-row justify-content-center align-items-center cooperation-card"
      data-aos="fade-up"
    >
      <div className="img-wrapper text-center mb-3 mb-md-0">
        <img src={imgPath} className={`${imgClassName}`} alt={`${name} logo`} />
      </div>
      <div className="px-4 d-flex flex-column text-wrapper">
        <h3>{name}</h3>
        <p className="mb-0">{description}</p>
      </div>
    </div>
  );
};

export default CooperationCard;
