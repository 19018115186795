import { useTranslation } from "react-i18next";

const ReadMoreButton = ({ openAction, closeAction, readMore }) => {
  const { t } = useTranslation("common");
  return (
    <button
      className="readMoreButton"
      onClick={readMore ? closeAction : openAction}
    >
      {readMore
        ? t("services.HideReadMoreButton")
        : t("services.ReadMoreButton")}
    </button>
  );
};

export default ReadMoreButton;
