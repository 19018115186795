import React, { useState } from "react";
import Viewer from "react-viewer";
import { useTranslation } from "react-i18next";
import "./ModalComponent.css";

const ModalComponent = ({ paragraphContent, logo, testimonial }) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const { language } = useTranslation("common").i18n;

  let refAvailable = testimonial !== undefined;
  let refImage = undefined;
  if (refAvailable) {
    if (typeof testimonial === "string") {
      refImage = testimonial;
    } else {
      if (testimonial[language] !== undefined) {
        refImage = testimonial[language];
      } else {
        if (testimonial["gb"] !== undefined) {
          refImage = testimonial["gb"];
        } else {
          refAvailable = false;
        }
      }
    }
  }

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <div>
      <div className="testimonial-item portfolio-item">
        <div
          onClick={refAvailable ? openModal : null}
          className="portfolio-links"
        >
          <p style={{ cursor: refAvailable ? "pointer" : "auto" }}>
            {paragraphContent}
          </p>
          <img
            src={logo.src}
            className="testimonial-img"
            alt={logo.alt}
            style={{ cursor: refAvailable ? "pointer" : "auto" }}
          />
        </div>

        <Viewer
          visible={modalIsOpen}
          onClose={closeModal}
          images={[{ src: refImage, alt: "" }]}
          onMaskClick={closeModal}
          noNavbar={true}
          noToolbar={true}
          zoomSpeed={0.2}
          defaultScale={1.3}
          minScale={1.3}
        />
      </div>
    </div>
  );
};

export default ModalComponent;
